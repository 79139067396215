import React from 'react';
import Aux from '../components/hoc/Aux';
import Container from 'react-bootstrap/Container'
const coursePage = (props)=> {

    return(
          <Aux>
            <h1>
              Attempting to learn
            </h1>
            <div className="container-fluid">
              <h2>Two side by side, one fluid one not conatiner,plain bootstrap version</h2>
              <h3>This main container of others, is container-fluid</h3>
              <div className="container bg-primary">
                Frist container normal
              </div>
              <div className="container-fluid bg-secondary">
                Second container fluid
              </div>
            </div>
            <div className="container-fluid">
              <h2>Second example, also a high end fluid container</h2>
              <h3>This one uses rows and cols, default grid</h3>
              <div className="row">
                <div className="col bg-warning">I am a col</div>
                <div className="col bg-danger text-light">I am another col</div>
              </div>
            </div>
            <div className="container-fluid">
              <h2>Third example, also a high end fluid container</h2>
              <h3>This one uses rows and cols, but with grid col width</h3>
              <div className="row">
                <div className="col-8 bg-info">I am an 8 col</div>
                <div className="col-4 bg-warning">I am a 4 col</div>
              </div>
            </div>
            <div className="container-fluid">
              <h2>Fourth example, also a high end fluid container</h2>
              <h3>This one uses rows and cols, but with grid col width</h3>
              <div className="row">
                <div className="col-1 bg-info">I am an 1 col</div>
                <div className="col-2 bg-warning">I am a 2 col</div>
                <div className="col-3 bg-info">I am an 3 col</div>
                <div className="col-4 bg-warning">I am a 4 col</div>
                <div className="col-2 bg-info">I am a 2 col</div>
              </div>
            </div>
            <div className="container">
              <h2>Fifth example, same as above, <strong>Non Fluid</strong></h2>
              <h3>This one uses rows and cols, but with grid col width</h3>
              <div className="row">
                <div className="col-1 bg-info">I am an 1 col</div>
                <div className="col-2 bg-warning">I am a 2 col</div>
                <div className="col-3 bg-info">I am an 3 col</div>
                <div className="col-4 bg-warning">I am a 4 col</div>
                <div className="col-2 bg-info">I am a 2 col</div>
              </div>
            </div>
            <hr></hr>
            <h1>Now for the react-bootstrap versions</h1>
            <Container fluid>
              <h2>Two side by side, one fluid one not conatiner,plain bootstrap version</h2>
              <h3>This main container of others, is container-fluid</h3>
              <Container className="primary bg-primary">
                Frist container normal
              </Container>
              <Container className="bg-secondary" fluid >
                Second container fluid
              </Container>
            </Container>
            <div className="container-fluid">
              <h2>Second example, also a high end fluid container</h2>
              <h3>This one uses rows and cols, default grid</h3>
              <div className="row">
                <div className="col bg-warning">I am a col</div>
                <div className="col bg-danger text-light">I am another col</div>
              </div>
            </div>
            <div className="container-fluid">
              <h2>Third example, also a high end fluid container</h2>
              <h3>This one uses rows and cols, but with grid col width</h3>
              <div className="row">
                <div className="col-8 bg-info">I am an 8 col</div>
                <div className="col-4 bg-warning">I am a 4 col</div>
              </div>
            </div>
            <div className="container-fluid">
              <h2>Fourth example, also a high end fluid container</h2>
              <h3>This one uses rows and cols, but with grid col width</h3>
              <div className="row">
                <div className="col-1 bg-info">I am an 1 col</div>
                <div className="col-2 bg-warning">I am a 2 col</div>
                <div className="col-3 bg-info">I am an 3 col</div>
                <div className="col-4 bg-warning">I am a 4 col</div>
                <div className="col-2 bg-info">I am a 2 col</div>
              </div>
            </div>
            <div className="container">
              <h2>Fifth example, same as above, <strong>Non Fluid</strong></h2>
              <h3>This one uses rows and cols, but with grid col width</h3>
              <div className="row">
                <div className="col-1 bg-info">I am an 1 col</div>
                <div className="col-2 bg-warning">I am a 2 col</div>
                <div className="col-3 bg-info">I am an 3 col</div>
                <div className="col-4 bg-warning">I am a 4 col</div>
                <div className="col-2 bg-info">I am a 2 col</div>
              </div>
            </div>
          </Aux>
          )
}

export default coursePage
